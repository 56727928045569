import React from 'react'
import { Container, Row } from 'reactstrap'
import { Mentor } from 'shared/types'
import { FormattedMessage } from 'gatsby-plugin-intl'
import GodmotherCard from './GodmotherCard'

interface GodmotherSection {
  mentors: Mentor[]
}
const GodmotherSection = ({ mentors }: GodmotherSection): JSX.Element => {
  return (
    <div className="section text-center no-padding-top">
      <Container>
        <h2 className="title">
          <FormattedMessage id="mentorship_mentor_title" />
        </h2>
        <Row>
          {mentors.map((mentor: Mentor) => (
            <GodmotherCard
              key={mentor.id}
              id={mentor.id}
              name={mentor.name}
              description={mentor.description}
              image={mentor.image}
              programs={mentor.programs}
            />
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default GodmotherSection

import Footer from 'components/Footer'
import Header from 'components/Header'
import MentorshipPage from 'components/MentorshipPage'
import PageHelmet from 'components/PageHelmet'
import ScrollToTop from 'components/ScrollToTop'
import { graphql } from 'gatsby'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { mapToMentors, mapToMentorsContentFields, mapToGeneralPageFields } from 'services'
import { baseUrl, genericdescription, genericTitle, homeImage } from 'shared/constants'
import { GODMOTHER_PAGE } from 'shared/routes'
import { ContentfulMentor, ContentfulMentorshipData, Location, Mentor } from 'shared/types'

interface Mentorship {
  location: Location
  data: ContentfulMentorshipData
}

const Mentorship = ({ location, data }: Mentorship): JSX.Element => {
  const content = mapToGeneralPageFields(data.contentfulGeneralPageData)
  const mentors: Mentor[] = data.contentfulMentorOrder.mentorOrder.map((node: ContentfulMentor) => mapToMentors(node))
  const mentorsContent = mapToMentorsContentFields(data.contentfulMentorsPage)
  return (
    <>
      <PageHelmet
        title={genericTitle}
        description={genericdescription}
        url={`${baseUrl}${GODMOTHER_PAGE}`}
        image={homeImage}
      />
      <ScrollToTop location={location} />
      <ToastContainer />
      <Header location={location} contributionUrl={content.contributionUrl} merchandiseUrl={content.merchandiseUrl} />
      <MentorshipPage mentors={mentors} mentorsContent={mentorsContent} />
      <Footer />
    </>
  )
}

export const query = graphql`
  query Mentors($locale: String) {
    contentfulMentorOrder(node_locale: { eq: $locale }) {
      mentorOrder {
        contentful_id
        mentorDescription {
          mentorDescription
        }
        mentorImage {
          fixed(height: 800, width: 800) {
            src
          }
        }
        mentorName
        mentorPrograms
      }
    }
    contentfulMentorsPage(node_locale: { eq: $locale }) {
      contentful_id
      mentorDescription {
        raw
      }
      juniorMentorsDescription {
        raw
      }
      juniorMentorsForm
      juniorMentorsTitle
      title
    }
    contentfulGeneralPageData {
      contentful_id
      contributionUrl
      merchandiseUrl
    }
  }
`

export default Mentorship

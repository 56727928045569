import React, { useState } from 'react'
import { Card, CardImg, CardBody, CardTitle, Badge, Col } from 'reactstrap'
import { PROGRAMS } from 'shared/constants'
import GodmotherDescription from './GodmotherDescription'

interface GodmotherCard {
  id: string
  name: string
  description: string
  image: string
  programs: string[]
}

const GodmotherCard = ({ id, name, description, image, programs }: GodmotherCard): JSX.Element => {
  const MAX_PROGRAMS = 4
  const [isOpen, setIsOpen] = useState(false)
  const getColor = (program: string) => PROGRAMS.find(({ label }) => label === program)?.value || ''
  const badgePrograms = programs.slice()

  for (let i = 0; i < MAX_PROGRAMS - programs.length; i++) {
    badgePrograms.push('')
  }

  return (
    <Col
      md="6"
      lg="4"
      id={id}
      cursor="pointer"
      onMouseEnter={() => {
        setIsOpen(true)
      }}
      onMouseLeave={() => {
        setIsOpen(false)
      }}
    >
      <Card className="godmother ml-auto mr-auto">
        <GodmotherDescription popoverOpen={isOpen} description={description} />
        <CardImg top src={image} alt={`Marraine ${name}`} />
        <CardBody className="godmother-cardbody">
          <CardTitle tag="h4" className="godmother">
            {name}
          </CardTitle>
          {badgePrograms.map((program, index) => (
            <Badge key={program || index} className="godmother-badge" color={getColor(program)}>
              {program}
            </Badge>
          ))}
        </CardBody>
      </Card>
    </Col>
  )
}

export default GodmotherCard

import React, { useState } from 'react'
import Select, { ValueType } from 'react-select'
import { Container, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap'
import { toast } from 'react-toastify'
import emailjs from 'emailjs-com'
import { Mentor } from 'shared/types'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import { ToastConfigs } from 'shared/constants'
import { JUNIOR } from 'shared/routes'
import fr from '../../intl/fr.json'
import en from '../../intl/en.json'

const FR_CONTACT_CONSENT = [
  {
    label: "Oui. J'accepte que ma marraine entre en contact avec moi.",
    value: 'OUI',
  },
  {
    label: "Non. Je N'accepte PAS que ma marraine entre en contact avec moi.",
    value: 'NON',
  },
]

const EN_CONTACT_CONSENT = [
  {
    label: 'Yes. I consent to having my mentor get in touch with me.',
    value: 'OUI',
  },
  {
    label: 'No. I DO NOT consent to having my mentor get in touch with me.',
    value: 'NON',
  },
]

interface Option {
  value: string
  label: string
}

interface MiniProgramFormSection {
  mentors: Mentor[]
  juniorMentorsDescription: React.ReactNode
  juniorMentorsTitle: string
}

const MiniProgramFormSection = ({
  mentors,
  juniorMentorsDescription,
  juniorMentorsTitle,
}: MiniProgramFormSection): JSX.Element => {
  const [godmother, setGodmother] = useState<Option | null>(null)
  const [email, setEmail] = useState('')
  const [questions, setQuestions] = useState('')
  const [prenom, setPrenom] = useState('')
  const [nom, setNom] = useState('')
  const [session, setSession] = useState('')
  const [contactConsent, setContactConsent] = useState<Option | null>(
    useIntl().locale === 'en' ? EN_CONTACT_CONSENT[0] : FR_CONTACT_CONSENT[0],
  )
  const [isDisabled, setIsDisabled] = useState(false)

  const resetInputs = () => {
    setGodmother(null)
    setEmail('')
    setQuestions('')
    setPrenom('')
    setNom('')
    setSession('')
    // eslint-disable-next-line
    setContactConsent(useIntl().locale === 'en' ? EN_CONTACT_CONSENT[0] : FR_CONTACT_CONSENT[0])
  }

  const sendEmail = () => {
    if (godmother && email && prenom && nom && session && contactConsent) {
      emailjs
        .send(
          process.env.GATSBY_EMAIL_SERVICE_ID || '',
          process.env.GATSBY_GODMOTHER_FORM_TEMPLATE_ID || '',
          {
            marrainage_type: 'junior',
            nom_etudiante: `${prenom} ${nom.toUpperCase()}`,
            nom_marraine: godmother.label,
            courriel_etudiante: email,
            session_etudiante: '-',
            programme_etudiante: session,
            consentement_contact: contactConsent.label,
            questions_etudiante: questions,
          },
          process.env.GATSBY_EMAIL_USER_ID || '',
        )
        .then(() => {
          toast.success('Formulaire envoyé!', ToastConfigs)
          resetInputs()
        })
        .catch(() => {
          toast.error("Erreur dans l'envoie du formulaire", ToastConfigs)
        })
        .finally(() => {
          setIsDisabled(false)
        })
    }
  }

  const sendRequest = () => {
    if (godmother && email && prenom && nom && session && contactConsent) {
      setIsDisabled(true)
      sendEmail()
    } else {
      toast.error('Veuillez remplir toutes les sections.', ToastConfigs)
    }
  }

  return (
    <div className="section text-center" id={JUNIOR}>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <h2 className="title">{juniorMentorsTitle}</h2>
            <h5 className="description">{juniorMentorsDescription}</h5>
          </Col>
        </Row>
        <Row>
          <FormGroup className="col-md-12">
            <Label for="inputState">
              <FormattedMessage id="mentorship_form_chooseMentor" />
            </Label>
            <Select
              classNamePrefix="godmother"
              name="marraine"
              value={godmother}
              options={mentors.map((mentor: Mentor) => ({
                value: mentor.name,
                label: mentor.name,
              }))}
              placeholder={<FormattedMessage id="mentorship_form_mentor" />}
              onChange={(value: ValueType<Option, false>) => {
                setGodmother(value as Option)
              }}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-md-6">
            <Label>
              <FormattedMessage id="mini_mentorship_form_year_profession" />
            </Label>
            <Input
              type="text"
              placeholder={
                useIntl().locale === 'en'
                  ? en.mini_mentorship_form_year_profession
                  : fr.mini_mentorship_form_year_profession
              }
              value={session}
              onChange={(e) => {
                setSession(e.target.value)
              }}
            />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="inputState">
              <FormattedMessage id="mini_mentorship_form_contactConsent" />
            </Label>
            <Select
              classNamePrefix="godmother"
              name="contactConsent"
              value={contactConsent}
              options={useIntl().locale === 'en' ? EN_CONTACT_CONSENT : FR_CONTACT_CONSENT}
              placeholder={<FormattedMessage id="mentorship_form_program" />}
              onChange={(value: ValueType<Option, false>) => {
                setContactConsent(value as Option)
              }}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-md-6">
            <Label>
              <FormattedMessage id="mentorship_form_firstname" />
            </Label>
            <Input
              type="text"
              placeholder={useIntl().locale === 'en' ? en.mentorship_form_firstname : fr.mentorship_form_firstname}
              value={prenom}
              onChange={(e) => {
                setPrenom(e.target.value)
              }}
            />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label>
              <FormattedMessage id="mentorship_form_name" />
            </Label>
            <Input
              type="text"
              placeholder={useIntl().locale === 'en' ? en.mentorship_form_name : fr.mentorship_form_name}
              value={nom}
              onChange={(e) => {
                setNom(e.target.value)
              }}
            />
          </FormGroup>
        </Row>
        <FormGroup>
          <Label>
            <FormattedMessage id="mentorship_form_email" />
          </Label>
          <Input
            type="email"
            placeholder={useIntl().locale === 'en' ? en.mentorship_form_email : fr.mentorship_form_email}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            <FormattedMessage id="mini_mentorship_form_questions" />
          </Label>
          <Input
            type="textarea"
            rows="4"
            placeholder={
              useIntl().locale === 'en' ? en.mini_mentorship_form_questions : fr.mini_mentorship_form_questions
            }
            value={questions}
            onChange={(e) => {
              setQuestions(e.target.value)
            }}
          />
        </FormGroup>
        <Button type="button" disabled={isDisabled} color="primary" onClick={sendRequest}>
          <FormattedMessage id="mentorship_form_btn_send" />
        </Button>
      </Container>
    </div>
  )
}

export default MiniProgramFormSection

import React from 'react'
import { CardBody } from 'reactstrap'

interface GodmotherDescription {
  popoverOpen: boolean
  description: string
}

const GodmotherDescription = ({ popoverOpen, description }: GodmotherDescription): JSX.Element => {
  return (
    <div className={`overlay ${popoverOpen ? 'overlay-hover' : ''}`}>
      <CardBody className="overlay-card godmother-overlay-card">{description}</CardBody>
    </div>
  )
}

export default GodmotherDescription

import React from 'react'
import { Col, Container, Row, Button } from 'reactstrap'
import { FormattedMessage } from 'gatsby-plugin-intl'

interface DescriptionSection {
  scrollToForm: VoidFunction
  title: string
  mentorDescription: React.ReactNode
}

const DescriptionSection = ({ scrollToForm, title, mentorDescription }: DescriptionSection): JSX.Element => {
  return (
    <div className="section text-center top-of-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <h2 className="title">{title}</h2>
            <h5 className="description">{mentorDescription}</h5>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Button type="button" color="primary" onClick={scrollToForm}>
            <FormattedMessage id="mentorship_btn_form" />
          </Button>
        </Row>
      </Container>
    </div>
  )
}

export default DescriptionSection

import React, { useState, RefObject } from 'react'
import Select, { ValueType } from 'react-select'
import { Container, Row, FormGroup, Label, Input, Button } from 'reactstrap'
import { toast } from 'react-toastify'
import emailjs from 'emailjs-com'
import { PROGRAMS, ToastConfigs } from 'shared/constants'
import { Mentor } from 'shared/types'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import fr from '../../intl/fr.json'
import en from '../../intl/en.json'

interface Option {
  value: string
  label: string
}

interface GodmotherFormSection {
  formRef: RefObject<HTMLDivElement> | null
  mentors: Mentor[]
}

const GodmotherFormSection = ({ formRef, mentors }: GodmotherFormSection): JSX.Element => {
  const [godmother, setGodmother] = useState<Option | null>(null)
  const [email, setEmail] = useState('')
  const [prenom, setPrenom] = useState('')
  const [nom, setNom] = useState('')
  const [session, setSession] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [programme, setProgramme] = useState<Option | null>(null)

  const resetInputs = () => {
    setGodmother(null)
    setEmail('')
    setPrenom('')
    setNom('')
    setSession('')
    setProgramme(null)
  }

  const sendEmail = (spreadsheetStatus: string, spreadsheetError: string) => {
    if (godmother && email && prenom && nom && session && programme) {
      emailjs
        .send(
          process.env.GATSBY_EMAIL_SERVICE_ID || '',
          process.env.GATSBY_GODMOTHER_FORM_TEMPLATE_ID || '',
          {
            marrainage_type: '',
            nom_etudiante: `${prenom} ${nom.toUpperCase()}`,
            nom_marraine: godmother.label,
            courriel_etudiante: email,
            session_etudiante: session,
            programme_etudiante: programme.label,
            spreadsheets_status: spreadsheetStatus,
            spreadsheets_error: spreadsheetError,
            questions_etudiante: 'AUCUNE',
          },
          process.env.GATSBY_EMAIL_USER_ID || '',
        )
        .then(() => {
          toast.success('Formulaire envoyé!', ToastConfigs)
          resetInputs()
        })
        .catch(() => {
          toast.error("Erreur dans l'envoie du formulaire", ToastConfigs)
        })
        .finally(() => {
          setIsDisabled(false)
        })
    }
  }

  const appendSpreadsheet = async () => {
    if (godmother && email && prenom && nom && session && programme) {
      let erreur: string | null = null

      const payload = JSON.stringify({
        email,
        prenom,
        nom,
        godmother: godmother.value,
        programme: programme.value,
        session,
      })

      fetch('https://e1zaslx1bc.execute-api.us-east-1.amazonaws.com/production/updateGoogleSpreadsheet', {
        method: 'POST',
        body: payload,
        redirect: 'follow',
      })
        .catch((e) => {
          erreur = e
        })
        .finally(() => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          erreur
            ? sendEmail(
                '',
                `Erreur lors de l'ajout au spreadsheet 
          https://docs.google.com/spreadsheets/d/${process.env.GATSBY_SPREADSHEET_ID}`,
              )
            : sendEmail(
                `Ajouté au spread sheet 
        https://docs.google.com/spreadsheets/d/${process.env.GATSBY_SPREADSHEET_ID}`,
                '',
              )
        })
    }
  }

  const sendRequest = () => {
    if (godmother && email && prenom && nom && session && programme) {
      setIsDisabled(true)
      appendSpreadsheet()
    } else {
      toast.error('Veuillez remplir toutes les sections.', ToastConfigs)
    }
  }

  return (
    <div className="section text-center" ref={formRef}>
      <Container>
        <h2 className="title">
          <FormattedMessage id="mentorship_form_title" />
        </h2>
        <Row>
          <FormGroup className="col-md-12">
            <Label for="inputState">
              <FormattedMessage id="mentorship_form_chooseMentor" />
            </Label>
            <Select
              classNamePrefix="godmother"
              name="marraine"
              value={godmother}
              options={mentors.map((mentor: Mentor) => ({
                value: mentor.name,
                label: mentor.name,
              }))}
              placeholder={<FormattedMessage id="mentorship_form_mentor" />}
              onChange={(value: ValueType<Option, false>) => {
                setGodmother(value as Option)
              }}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-md-6">
            <Label>
              <FormattedMessage id="mentorship_form_firstSemester" />
            </Label>
            <Input
              type="text"
              placeholder={useIntl().locale === 'en' ? en.mentorship_form_semester : fr.mentorship_form_semester}
              value={session}
              onChange={(e) => {
                setSession(e.target.value)
              }}
            />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="inputState">
              <FormattedMessage id="mentorship_form_fieldStudy" />
            </Label>
            <Select
              classNamePrefix="godmother"
              name="programme"
              value={programme}
              options={PROGRAMS}
              placeholder={<FormattedMessage id="mentorship_form_program" />}
              onChange={(value: ValueType<Option, false>) => {
                setProgramme(value as Option)
              }}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col-md-6">
            <Label>
              <FormattedMessage id="mentorship_form_firstname" />
            </Label>
            <Input
              type="text"
              placeholder={useIntl().locale === 'en' ? en.mentorship_form_firstname : fr.mentorship_form_firstname}
              value={prenom}
              onChange={(e) => {
                setPrenom(e.target.value)
              }}
            />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label>
              <FormattedMessage id="mentorship_form_name" />
            </Label>
            <Input
              type="text"
              placeholder={useIntl().locale === 'en' ? en.mentorship_form_name : fr.mentorship_form_name}
              value={nom}
              onChange={(e) => {
                setNom(e.target.value)
              }}
            />
          </FormGroup>
        </Row>
        <FormGroup>
          <Label>
            <FormattedMessage id="mentorship_form_email" />
          </Label>
          <Input
            type="email"
            placeholder={useIntl().locale === 'en' ? en.mentorship_form_email : fr.mentorship_form_email}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
        </FormGroup>
        <Button type="button" disabled={isDisabled} color="primary" onClick={sendRequest}>
          <FormattedMessage id="mentorship_form_btn_send" />
        </Button>
      </Container>
    </div>
  )
}

export default GodmotherFormSection
